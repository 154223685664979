import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import * as AboutStyles from "./../components/about.module.css"
import { LanguageContext } from "../utils/LanguageContext"

function getDescriptionFromPrismicData(data, language) {
  if (language === "Spanish") {
    return data.description__spanish_.reduce((acc, cur) => [...acc, cur.text, <><br /><br /></>], [])
  } else if (language === "English") {
    return data.description__english_.reduce((acc, cur) => [...acc, cur.text, <><br /><br /></>], [])
  } else {
    return `
      ${data.description__spanish_.reduce((acc, cur) => [...acc, cur.text, <><br /><br /></>], [])}
      \n\n
      ${data.description__english_.reduce((acc, cur) => [...acc, cur.text, <><br /><br /></>], [])}
    `
  }
}

const AboutMePage = ({ data }) => {
  const { aboutPage: { data: aboutPageData } } = data
  const titleText = aboutPageData.title[0].text

  return (
    <Layout pageTitle={titleText}>
      <Seo
        title="About Irene Bielsa"
        pathname={"/about"}
        keywords={["Irene Bielsa", "about Irene Bielsa", 'Irene Bielsa Zaragoza', 'Irene Bielsa Spain']}
      />

      <div className={AboutStyles.container}>
        <LanguageContext.Consumer>
          {({ language }) => {
            return (
              <p className={AboutStyles.description}>
                {getDescriptionFromPrismicData(aboutPageData, language)}
              </p>
            )
          }}
        </LanguageContext.Consumer>

        <div className={AboutStyles.imageContainer}>
          <img
            srcSet={`${aboutPageData.image__mobile_.url} 1536w, ${aboutPageData.image__desktop_.url} 1800w`}
            sizes="(min-width: 768px) 1800px, 100vw"
            src={aboutPageData.image__mobile_.url}
            alt="Irene Bielsa"
          />
        </div>
      </div>
    </Layout>
  )
}

export default AboutMePage

export const query = graphql`
  query profileImage {
    file(name: { eq: "profile" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }
    aboutPage: prismicAboutPage {
      data {
        description__english_ {
          text
        }
        description__spanish_ {
          text
        }
        image__desktop_ {
          url
        }
        image__mobile_ {
          url
        }
        title {
          text
        }
      }
    }
  }
`
